* {
  font-family: "Overpass", sans-serif;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--input-background) inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--ion-color-primary);
  box-shadow: 0 0 0px 1000px transparent inset;
  -moz-transition: background-color 5000s ease-in-out 0s;
  -ms-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

.flex {
  display: flex;
}

.after::after, .before::before {
  display: block;
  content: '';
}

.column {
  flex-direction: column;
}

.custom-toast {
  --background: var(--input-background);
  --color: #ffffff;
  --box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.custom-toast.wrong {
  --background: #f82929;
}

.custom-toast.good {
  --background: #47aa20;
}

.custom-toast::part(message) {
  font-size: 16px;
  font-weight: bold;
}

/* Spinner */

.spinner-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: .5s;
}

.spinner-loader.inactive {
  height: 0px;
}

.spinner-large {
  width: 70px;
  height: 70px;
}

.spinner-small {
  width: 15px;
  height: 15px;
}

.spinner-large.center {
  margin: 50px auto 0;
}

/* IonItem */

ion-item {
  border: none;
  --inner-border-width: 0;
}

ion-tab-button.tab-selected {
  --background: #c56226;
}

ion-tab-button ion-icon, ion-tab-button img {
  opacity: 0.5;
  max-height: 25px;
}

ion-tab-button.tab-selected ion-icon, ion-tab-button.tab-selected img {
  opacity: 1;
}

ion-tab-button.tab-selected ion-icon {
  fill: white;
}

.main-header .menu-button {
  height: 100%;
  width: 64px;
  margin: 0;
  background: var(--fourth-background);
}

.main-header .menu-button-right ion-icon {
  fill: #A6A6A6;
}

.main-header .menu-button img {
  width: 36px;
}

ion-refresher {
  z-index: 2 !important;
}

.md ion-refresher {
  top: 7px;
}

.md .refresher-refreshing-icon, .md .refresher-pulling-icon {
  background: #383838 !important;
  border: none !important;
}

ion-toolbar {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

.main-toolbar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-tab-button ion-label {
  margin-top: 2px;
  margin-bottom: 0px;
}

/* ion-input[type=password] {
  letter-spacing: 10px;
} */

/* IonSearchbar */

.searchbar {
  width: 220px;
  height: 25px;
  margin-right: 15px;
  padding-left: 0;
  padding-right: 0;
  --background: var(--btn-background);
  --border-radius: 3px;
}

.searchbar .searchbar-input-container {
  height: inherit;
}

.searchbar .searchbar-input {
  padding-left: 10px !important;
  font-size: 12px;
  line-height: 18px;
}

.searchbar .searchbar-search-icon {
  width: 14px;
  left: inherit;
  right: 8px;
}

.searchbar .searchbar-clear-button, .searchbar input[type="search"]::-webkit-search-cancel-button {
  display: none !important;
}

.searchbar .search-next {
  position: absolute;
  right: 8px;
  width: 14px;
  height: 100%;
  z-index: 9999;
  cursor: pointer;
}

/* IonSelect */

.popover-without-shade {
  background: var(--btn-background);
  border: 1px solid var(--segment-border);
  border-radius: 5px;
  width: 130px;
  height: 28px;
  font-size: 13px;
  line-height: 15px;
  margin-right: 8px;
}

/* IonSelect с двойными кастомными стрелками */

.select-item {
  width: 130px;
  height: 28px;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  border: 1px solid var(--segment-border);
  border-radius: 5px;
  --background: var(--btn-background);
  --background-hover: transparent;
  --background-activated: transparent;
  --inner-padding-end: 8px;
}

.select-item::part(native) {
  min-height: auto;
}

.select-item ion-select {
  padding: 0;
  padding-left: 10px;
  border: none;
  border-radius: 0;
}

.select-item ion-select::part(icon) {
  display: none;
  /* убираем дефолтную стрелку */
}

.select-item ion-icon {
  min-width: 6px;
  width: 6px;
  height: 10px;
  fill: var(--filters-icon);
}

/* IonTextArea */

ion-textarea.text-area {
  --padding-start: 20px;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  --background: var(--first-background) !important;
  --color: var(--ion-item-color, var(--ion-text-color, #000));
}

ion-toast {
  position: fixed;
  transform: translateZ(5px);
  --color: #ffffff;
}

/* Calendar */

.calendar-container {
  width: 175px;
  height: 28px;
  margin-left: 12px;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  background: var(--btn-background);
  border: 1px solid var(--segment-border);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.desktop-live-container-header .calendar-container, .tablet-cards-container .home-top-container-btns .calendar-container, .main-mobile .home-top-container-btns .calendar-container {
  width: auto;
  min-width: 175px;
}

.calendar-label {
  margin-right: 5px;
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}

.calendar-label span {
  margin-left: 5px;
}

.calendar-container svg {
  width: 12px;
  min-width: 12px;
  fill: var(--account-image);
}

/* ImageUploader */

.image-uploader {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.image-uploader .fileContainer {
  background: none;
  box-shadow: none;
  transition: none;
}

.image-uploader .fileContainer .chooseFileButton {
  padding: 10px 20px 7px;
  background: var(--orange);
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  border: none;
}

.image-uploader .fileContainer .chooseFileButton:hover {
  background: var(--orange);
}

.image-uploader .fileContainer .uploadPictureContainer {
  width: 25%;
  margin: 5%;
  padding: 0;
  background: none;
  box-shadow: none;
  border: none;
  position: relative;
}

.image-uploader .fileContainer .deleteImage {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  color: #D83F3F;
  background: #D83F3F;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  white-space: nowrap;
}

.image-uploader .fileContainer .deleteImage::before {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 9px;
  top: 8px;
  background: url(../images/close-white.svg) no-repeat;
  background-size: 100% 100%;
  /* background-position: center center; */
}

/* Other */

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.desktop-live-container-header {
  background: var(--inner-header-background);
  flex: 0 0 50px;
}

/* Media */

@media screen and (min-width: 992px) {
  #root ion-tab-bar {
    display: none !important;
  }
  .desktop {
    display: flex;
  }
}

.orange-select {
  --background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --inner-padding-end: 0;
}

.orange-select ion-select {
  padding: 0;
  color: var(--orange);
  --background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --inner-padding-end: 0;
}

.orange-select ion-select::part(icon) {
  display: none;
}

.orange-select ion-icon {
  min-width: 12px;
  width: 12px;
  margin-left: 5px;
  height: 7px;
  margin-bottom: 4px;
  fill: var(--orange);
  stroke: var(--orange);
}

.input-container-light:not(:first-child) {
  margin-top: 12px;
}

.input-label {
  margin-bottom: 5px;
  font-size: 15px;
  color: var(--opacity-text-color);
}

.input-container-light ion-item {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  --background: var(--first-background);
}

.input-container-light ion-item ion-icon {
  width: 18px;
  height: 18px;
}

.input-container-light ion-icon.password-eye {
  fill: #C5C5C5;
  cursor: pointer;
}

.merge-input-select {
  width: 100%;
  display: flex;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.merge-input-select ion-item {
  border: none;
}

.merge-input-select ion-select {
  min-width: 106px;
  height: 44px;
  border-radius: 5px 0 0 5px;
  border-right: 1px solid var(--input-border);
  background: var(--third-background);
}

.input-container-dark ion-select {
  background: var(--select-bgr);
}

.phone-container ion-select {
  --padding-start: 40px;
}

.phone-container ion-select::before {
  display: block;
  content: '';
  width: 24px;
  height: 16px;
  background-image: url('../images/flag.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 7px;
}

.merge-input-select ion-select::part(icon) {
  display: none;
}

.merge-input-select ion-select::after {
  display: block;
  content: '';
  width: 11px;
  height: 7px;
  background-image: var(--arrow-code-icon);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 12px;
}

.merge-input-select ion-select::part(text) {
  margin-top: 4px;
}

.merge-input-select ion-item {
  border-radius: 0px 5px 5px 0px;
  flex-grow: 1;
}

.merge-input-select ion-item.without-select {
  border-radius: 5px;
}

.input-container-dark .merge-input-select {
  /* border: 1px solid var(--segment-border); */
  border-radius: 5px;
}

.input-container-dark ion-item {
  --inner-padding-end: 0;
  --background: var(--first-background);
  flex-grow: 1;
}

ion-item .input-clear-icon.sc-ion-input-ios {
  background-image: url("../images/cross-red.svg");
  background-size: 14px;
}

.top-segment.dark-segment {
  background: var(--first-background);
}

.required {
  color: var(--old-red) !important;
}

.top-segment {
  height: 32px;
  background: var(--second-background);
  border: 1px solid var(--segment-border);
  border-radius: 5px;
  padding: 1px;
  box-shadow: 0px 0.5px 4px rgb(0 0 0 / 12%), 0px 6px 13px rgb(0 0 0 / 12%);
}

.top-segment ion-segment-button {
  height: 100%;
  margin-top: 0px;
  padding-top: 2px;
  --border-radius: 4px;
  --margin-start: 0px;
  --color: var(--opacity-text-color);
  --indicator-color: var(--orange);
  --color-checked: #fff;
}

.top-segment ion-segment-button ion-label {
  margin: 0;
  font-size: 15px;
}

.toggle-card {
  --background: var(--toggle-blue);
  --background-checked: var(--toggle-pink);
  height: 21px;
  width: 37px;
  min-width: 37px;
  --handle-border-radius: 50%;
  --border-radius: 20px;
  --handle-width: 14.5px;
  --handle-height: 14.5px;
  --handle-spacing: 3px;
}

.orange-toggle {
  --background: var(--ion-toggle);
  --background-checked: var(--orange);
}

.logout-btn {
  width: auto;
  height: 48px;
  min-height: 48px;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  --color: #FF5050;
  --background: rgba(255, 80, 80, 0.1);
  --ripple-color: #e6e6e6;
  text-transform: inherit;
  --background-hover: #e6e6e6;
  --background-focused: #e6e6e6;
  --background-activated: #e6e6e6;
}

ion-tab-bar {
  --background: var(--first-background);
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.19);
  border-top: none;
  height: 64px;
}

ion-tab-button ion-label {
  margin-top: 4px;
  font-size: 13px;
}

ion-tab-button.tab-selected ion-label {
  color: #fff;
}

:not(ion-segment-button).ion-activatable {
  overflow: hidden;
}

/* .home-top-segment ion-segment-button.ion-activatable {
  overflow: inherit;
} */

/* Иконки */

.filters-icon {
  fill: var(--filters-icon);
  cursor: pointer;
}

.lock-pic {
  height: 20px;
  margin: 0 auto;
  fill: var(--ion-color-primary);
  opacity: .3;
}

.ultra svg.lock-pic, .list-of-bets.ultra .desktop-card-event-cfc-container .lock-pic {
  width: 40px;
  height: 40px;
}

.component-responsive-image, .component-responsive-image-size {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  ion-tab-bar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .input-container-dark .merge-input-select ion-select {
    background: var(--select-tablet-bgr);
  }
  .wallet-loan-data .input-container-dark .merge-input-select ion-select {
    background: var(--second-background);
  }
}

@media screen and (max-width: 768px) {
  #root .menu-buttons-wrapper {
    border-bottom: none !important;
  }
  .account-links ion-item::part(detail-icon) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  ion-fab {
    bottom: 75px;
  }
}