@media screen and (min-width: 3400px) {
   .desktop-main-page {
      --padding-top: 134px !important;
   }
   .top-segment {
      height: 54px;
      padding: 2px;
      border-width: 2px;
      border-radius: 10px;
   }
   .top-segment ion-segment-button {
      --border-radius: 8px;
   }
   .top-segment ion-segment-button ion-label {
      font-size: 27px;
   }
   .orange-select ion-select {
      font-size: 30px;
   }
   .orange-select ion-icon {
      min-width: 30px;
      height: 14px;
   }
   .logout-btn {
      height: 82px;
      min-height: 82px;
      font-size: 30px;
   }
   .toggle-card {
      width: 70px;
      min-width: 70px;
      height: 37px;
      --handle-width: 27px;
      --handle-height: 27px;
   }
   /* Inputs */
   ion-item .input-clear-icon.sc-ion-input-ios {
      background-size: 28px;
   }
   .input-container-light:not(:first-child) {
      margin-top: 30px;
   }
   .input-container-light ion-item {
      --padding-start: 30px;
      --padding-end: 15px;
      font-size: 30px;
      border-radius: 10px;
   }
   .input-container-light ion-input {
      height: 74px;
   }
   .input-container-light ion-item ion-icon {
      width: 30px;
      height: 30px;
   }
   .input-container-dark ion-item {
      --padding-start: 30px;
      --padding-end: 15px;
      font-size: 30px;
   }
   .input-container-dark ion-input {
      height: 74px;
   }
   .merge-input-select {
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
   }
   .input-container-dark .merge-input-select {
      border-width: 2px;
      border-radius: 10px;
   }
   .merge-input-select ion-select {
      min-width: 180px;
      height: 74px;
      padding-left: 30px;
      font-size: 30px;
      border-radius: 10px 0 0 10px;
   }
   .phone-container ion-select::before {
      width: 40px;
      height: 27px;
      left: 12px;
   }
   .merge-input-select ion-select::after {
      width: 30px;
      height: 14px;
   }
   .sc-ion-popover-ios-h {
      --width: auto;
   }
   ion-item.select-interface-option {
      font-size: 30px;
      padding: 8px 0;
   }
   .phone-container.merge-input-select ion-select::part(text) {
      margin-left: 35px;
   }
}