/* Login & Forgot */

.verify-input-container input {
  text-transform: uppercase !important;
  font-weight: bold !important;
}

.login.ion-page {
  min-height: 100vh;
  z-index: 10;
}

.login-left-block {
  height: 100%;
  flex: 0 0 40%;
  padding: 40px;
  -webkit-box-flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.login-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: var(--login-background);
}

.login-title {
  font-weight: bold;
  font-size: 33px;
  line-height: 114.2%;
}

.login-description {
  max-width: none;
  margin-top: 10px;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: var(--opacity-text-color);
}

.login-fields {
  width: 100%;
  max-width: 316px;
  margin: 0 auto;
}

.login-input-container {
  width: 100%;
  margin: 11px auto;
}

.login-input-container .login-input {
  width: 100%;
  height: 46px;
  padding-top: 5px;
  border-radius: 5px;
  background: var(--input-background);
  border: 1px solid var(--input-border);
  padding-left: 12px;
  outline: none;
}

.login-input-container .login-input::placeholder {
  opacity: .5;
  color: var(--text-white);
  font-size: 15px;
}

.login-input-container ion-item {
  border-radius: 5px;
  --background: var(--input-background);
  border: 1px solid var(--input-border);
  --padding-start: 12px;
}

.login-input-container ion-input {
  width: 100%;
  --placeholder-color: var(--text-white);
  --padding-start: 12px !important;
}

.login-input-container ion-icon {
  width: 13px;
  height: 11px;
  margin-right: 0;
  margin-left: 0;
  opacity: .5;
}

.login-input-container ion-icon.pass-eye-icon {
  cursor: pointer;
}

.main-button {
  height: 47px;
  width: 100%;
  margin-top: 40px;
  text-transform: capitalize;
  font-weight: bold;
  --color: #ffffff;
  --background: var(--orange);
  --background-hover: var(--orange-light);
  --background-activated: var(--orange-light);
  --background-focused: var(--orange-light);
  --transition: .3s;
  --border-radius: 5px;
}

button.main-button {
  height: 47px;
  width: 100%;
  margin-top: 40px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: var(--orange);
  transition: .3s;
  border-radius: 5px;
  outline: none;
  position: relative;
}

button.main-button:hover, button.main-button:focus, button.main-button:active {
  background: var(--orange-light);
}

.login-toolbar {
  opacity: .5;
  margin-top: 11px;
  margin-left: 20px;
  position: absolute;
  z-index: 100;
}

.login-container .login-toolbar {
  --background: none;
}

.login-toolbar ion-icon {
  color: var(--text-white);
}

.login-right-block {
  flex: 0 0 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-flex: 0;
}

.right-block-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.center-logo {
  width: 196px;
  height: 40px;
  margin: 0 auto;
}

.center-logo-dark {
  fill: #E9E9E9;
}

.center-logo-light {
  fill: #000000;
}

.top-logo-block {
  margin-bottom: 60px;
}

.monkey-image-wrapper {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: #2E2E2E;
  overflow: hidden;
}

.monkey-white-image {
  width: auto;
  height: 85%;
}

.monkey-black-image {
  height: 205px;
  display: block;
  margin: 0 auto 30px;
}

.top-logo-block .center-logo {
  width: 224px;
  height: 46px;
  margin-top: 30px;
}

.top-logo-block .center-logo-dark {
  opacity: .5;
}

/* Forgot */

.forgot-password {
  display: inline-block;
  font-size: 14px;
  margin-left: 0;
  text-decoration: none;
  color: var(--opacity-text-color);
  cursor: pointer;
}

.forgot-password-container .main-button {
  margin-top: 15px;
}

.verify-input-container ion-input {
  height: 56px;
  margin: 0 3px;
  --padding-start: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  --background: var(--input-background);
  border: 1px solid var(--segment-border);
}

/* Rules */

.rules.ion-page {
  min-height: 100vh;
  z-index: 10;
}

.rules-container {
  height: 100%;
  background: var(--login-background);
}

.rules-left-block {
  width: 100%;
  height: 90%;
  flex: 0 0 65%;
  max-width: 970px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.rules-title {
  font-size: 33px;
  font-weight: bold;
  margin-bottom: 35px;
  margin-left: 25px;
}

.rules-text {
  background: var(--rules-text-bgr);
  border: 1px solid var(--segment-border);
  border-radius: 5px;
  padding: 0 25px;
  font-size: 14px;
  line-height: 20px;
  overflow-y: auto;
}

.rules-text p {
  opacity: .9;
}

.rules-right-block {
  flex: 0 0 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rules-button {
  max-width: 320px;
}

.rules-button-container {
  justify-content: flex-end;
}

/* Edit Login  */

.edit-login.ion-page {
  min-height: 100vh;
  z-index: 10;
}

.edit-login-description {
  margin-bottom: 25px;
}

.login-label-name {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  color: var(--opacity-text-color);
}

.login-label-name .require {
  color: #df4646;
}

.login-phone-container ion-item {
  --padding-start: 0;
}

.login-phone-container ion-input {
  --padding-start: 20px !important;
}

.login-phone-container ion-select {
  min-width: 106px;
  height: 44px;
  --padding-start: 40px;
  border-right: 1px solid var(--segment-border);
}

.login-phone-container ion-select::before {
  display: block;
  content: '';
  width: 24px;
  height: 16px;
  background-image: url('../images/flag.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 7px;
}

.login-phone-container ion-select::part(icon) {
  display: none;
}

.login-phone-container ion-select::after {
  display: block;
  content: '';
  width: 11px;
  height: 7px;
  background-image: var(--arrow-code-icon);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 12px;
}

.login-phone-container ion-select::part(text) {
  margin-top: 4px;
}

.edit-login .main-button {
  margin-top: 25px;
}

@media screen and (min-width: 1025px) {
  /* .login-left-block .top-logo-block {
    display: none;
  } */
  .login-left-block .center-logo {
    display: none;
  }
  .forgot-password-container .login-toolbar {
    display: flex;
    position: absolute;
    top: 70px;
    left: 70px;
    width: 100px;
  }
}

@media screen and (max-width: 1024px) {
  /* Login Page */
  .login-left-block {
    flex-basis: 100%;
  }
  .login-right-block {
    display: none;
  }
  /* Rules */
  .rules-left-block {
    flex-basis: 100%;
    height: 100%;
  }
  .rules-right-block {
    display: none;
  }
  /* Edit */
  .login-label-name {
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  /* .monkey-image-wrapper, .monkey-image {
    display: none;
  } */
  .login-container {
    /* height: auto; */
    /* padding-bottom: 20px; */
    /* display: block; */
    /* align-items: center; */
  }
  .login-left-block {
    /* height: auto; */
    padding: 20px;
    /* display: block; */
    /* justify-content: flex-start; */
    /* justify-content: inherit; */
  }
  /* .login-left-block.flex-end {
    justify-content: flex-end;
  } */
  .top-logo-block {
    margin-bottom: 30px;
    /* margin-bottom: 0; */
  }
  .top-logo-block.nomonkey {
    display: none;
  }
  .top-logo-block .monkey-image {
    /* width: 80px; */
    /* height: auto; */
    width: auto;
    height: 145px;
    margin-bottom: 0;
  }
  .top-logo-block .center-logo {
    height: 30px;
    margin-top: 10px;
  }
  .login-fields {
    max-width: 250px;
    /* margin-top: 30px; */
    /* padding-bottom: 40px; */
  }
  .login-title {
    font-size: 24px;
  }
  .login-description {
    margin-bottom: 30px;
  }
  .login-input-container {
    margin: 8px auto;
  }
  .login-input-container ion-icon {
    display: none;
  }
  .forgot-password {
    margin-left: auto;
  }
  .login-container.forgot-password-container {
    padding-top: 100px;
  }
  .login-phone-container ion-select {
    min-width: 92px;
    --padding-start: 35px;
    font-size: 14px;
  }
  .login-input-container ion-item {
    --padding-start: 0;
  }
  .login-input-container ion-input {
    font-size: 14px;
  }
  .login-phone-container ion-input {
    --padding-start: 12px !important;
  }
  button.main-button {
    margin-top: 30px;
    font-size: 15px;
  }
  /* Rules */
  .rules-left-block {
    padding: 20px;
  }
  .rules-title {
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .rules-text {
    padding: 0 10px;
    font-size: 13px;
  }
  .rules-button {
    margin-top: 25px;
  }
}

@media screen and (min-width: 3400px) {
  .monkey-black-image, .monkey-image-wrapper {
    display: none;
  }
  /* Login */
  .login-left-block {
    padding: 80px;
  }
  .login-fields {
    max-width: 660px;
  }
  .login-title {
    font-size: 65px;
  }
  .monkey-image-wrapper {
    width: 360px;
    height: 360px;
  }
  .login-description {
    margin-top: 30px;
    margin-bottom: 110px;
    font-size: 36px;
    line-height: 55px;
  }
  .main-button {
    height: 100px;
    margin-top: 75px;
    font-size: 36px;
    --border-radius: 10px;
  }
  button.main-button {
    height: 100px;
    margin-top: 75px;
    font-size: 36px;
    border-radius: 10px;
  }
  .login-input-container {
    margin: 23px auto;
  }
  .login-input-container ion-item {
    border-width: 2px;
    border-radius: 10px;
    --padding-start: 24px;
  }
  .login-input-container ion-input {
    height: 92px;
    --padding-start: 24px !important;
    font-size: 36px;
  }
  .login-input-container ion-icon {
    width: 23px;
    height: 27px;
  }
  .login-input-container ion-icon.pass-eye-icon {
    margin-right: 15px;
  }
  .forgot-password {
    font-size: 36px;
  }
  .login-back-btn {
    margin-top: 100px;
    margin-left: 80px;
  }
  .login-back-btn ion-icon {
    width: 100px;
    height: 60px;
  }
  .center-logo {
    width: 430px;
    height: 88px;
    margin: 0 auto;
  }
  /* Forgot */
  .verify-input-container ion-input {
    height: 120px;
    margin: 0 6px;
    border-width: 2px;
    border-radius: 10px;
    font-size: 36px;
  }
  .forgot-password-container .main-button {
    margin-top: 30px;
  }
  /* Rules */
  .rules-left-block {
    max-width: 1950px;
    padding: 80px;
  }
  .rules-title {
    font-size: 65px;
    margin-bottom: 75px;
    margin-left: 45px;
  }
  .rules-text {
    padding: 0 45px;
    border-radius: 10px;
    font-size: 36px;
    line-height: 48px;
  }
  .rules-button {
    max-width: 850px;
  }
  /* Edit */
  .edit-login-description {
    margin-bottom: 50px;
  }
  .login-label-name {
    margin-top: 40px;
    margin-bottom: 18px;
    font-size: 36px;
  }
  .login-input-container .input-container-light ion-input {
    height: 92px;
  }
  .login-input-container .merge-input-select ion-select {
    min-width: 180px;
    height: 92px;
  }
  .login-phone-container ion-select {
    min-width: 225px;
    height: 100%;
    border-width: 2px;
    --padding-start: 80px;
  }
  .login-phone-container ion-select::before {
    width: 48px;
    height: 32px;
    left: 14px;
  }
  .login-phone-container ion-select::after {
    width: 22px;
    height: 14px;
    right: 24px;
  }
  .login-phone-container ion-select::part(text) {
    margin-top: 10px;
    font-size: 36px;
  }
  .login-phone-container ion-input {
    --padding-start: 40px !important;
  }
  .input-container-dark ion-input {
    height: 92px;
  }
  .login-input-container .top-segment {
    height: 67px;
  }
  .login-input-container .top-segment ion-segment-button ion-label {
    font-size: 33px;
  }
}