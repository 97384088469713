/* .list-of-bets-header p {
  font-weight: bold;
  font-size: 20px;
  line-height: 114.2%;
  color: #E9E9E9
} */
/* .spinner-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .spinner-large {
  width: 70px;
  height: 70px;
}
.spinner-large.center {
  margin: 50px auto 0;
} */

.desktop-list-of-bets-wrapper {
  min-width: 520px;
  width: 140%;
  height: 100%;
  margin-right: 10px;
  padding: 10px 0;
  display: flex;
}
.list-of-bets {
  -webkit-animation: .5s fadein;
  animation: .5s fadein;
}
@-webkit-keyframes fadein { from { opacity:.6; } to { opacity: 1; }  }
@keyframes fadein { from { opacity:.6; } to { opacity: 1; }  }
.list-of-bets {
  width: 100%;
  height: 100%;
  background: var(--left-bar-background);
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
}
.close-betslip-icon {
  margin-left: auto;
  margin-right: 5px;
  cursor: pointer;
  fill: var(--close-card-icon);
  opacity: .7;
  transition: .3;
}
.close-betslip-icon:hover {
  opacity: 1;
}
.list-of-bets-body {
  max-height: calc(100% - 50px);
  overflow: auto;
}

.list-of-bets-filter-container {
  margin-top: 15px;
  padding: 0 10px 0 30px;
  justify-content: space-between;
}

.list-of-bets-filter-btns {
  flex-wrap: wrap;
}

.list-of-bets-filter-btn {
  min-width: 64px;
  height: 30px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 6px 13px;
  text-align: center;
  background: var(--second-background);
  /* border-bottom: 1px solid var(--orange); */
  border-radius: 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: var(--list-of-bets-btn-color);
  opacity: .5;
  position: relative;
  cursor: pointer;
}

.list-of-bets-filter-btn.bright {
  opacity: 1;
}

.btn-hl {
  /* width: 70%; */
  height: 1px;
  background: var(--orange);
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  bottom: 1px;
  left: 12px;
  right: 12px;
}

.list-of-bets-filter-settings {
  width: 26px;
  min-width: 26px;
  height: 26px;
  margin-top: 2px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  background: var(--second-background);
  border-radius: 5px;
  cursor: pointer;
}

.list-of-bets-filter-settings svg {
  width: 12px;
  fill: var(--account-image);
}

.list-of-bets .desktop-live-container-header {
  height: 50px;
  padding-left: 30px;
  padding-right: 10px;
  justify-content: start;
}

.list-of-bets .desktop-live-container-header .title {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid var(--color-grey);
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
}

.list-of-bets .desktop-live-container-header .time {
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: var(--color-grey);
}

.list-of-bets-header {
  margin-top: 35px;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
}

.list-of-bets-header-left {
  font-weight: bold;
  font-size: 20px;
  line-height: 114.2%;
  /* color: #E9E9E9 */
}

.list-of-bets-header-right {
  align-items: center;
}

.list-of-bets-header-right .more-icon {
  width: 21px;
  height: 21px;
  margin-left: 25px;
}

.list-of-bets-header-right .update-icon, .list-of-bets-header-right .star-icon {
  margin-left: 20px;
}

.desktop-card-event-cfc-container {
  width: 100%;
  padding: 10px 30px;
  margin-bottom: 5px;
  background: var(--live-container-background);
  /* height: 60px; */
  height: auto;
  overflow: hidden;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
}

.dark .desktop-card-event-cfc-container {
  box-shadow: none;
}

.desktop-card-event-cfc-container.close {
  height: 37px;
}

/* .desktop-card-event-cfc-container.close .desktop-card-event-cfc-container-btns {
  height: 0;
} */

.desktop-card-event-cfc-container-header {
  margin-bottom: 10px;
  align-items: center;
  /* position: relative; */
}

.desktop-card-event-cfc-container-header img {
  cursor: pointer;
}

.desktop-card-event-cfc-container-header-label {
  margin-right: auto;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
}

.desktop-card-event-cfc-container-header-right {
  justify-content: flex-end;
  align-items: center;
}

.desktop-card-event-cfc-container-header-label.small, .desktop-card-event-cfc-container-header-right.small {
  width: 25%;
}

.desktop-card-event-cfc-container-header-right.small {
  margin-left: auto;
}

.arrow-down {
  fill: var(--arrow-icon-color);
}

.desktop-card-event-cfc-container .arrow-down {
  margin-left: 20px;
  cursor: pointer;
  transition: .3s;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

.desktop-card-event-cfc-container.close .arrow-down {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.desktop-card-event-cfc-container-btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  -moz-column-gap: 5px;
  column-gap: 5px;
  row-gap: 5px;
}

.desktop-card-event-cfc-container-btns.two-columns {
  grid-template-columns: repeat(2, 1fr);
}

.desktop-card-event-cfc-container-btns .left-column,
.desktop-card-event-cfc-container-btns .right-column {
  width: 100%;
  display: grid;
  row-gap: 5px;
}

/* .desktop-card-event-cfc-container-btns .left-column .desktop-card-event-btn,
.desktop-card-event-cfc-container-btns .right-column .desktop-card-event-btn {
  width: 100%;
} */

.desktop-card-event-cfc-container-btns .desktop-card-event-btn {
  /* flex: 1 1 30%; */
  /* min-width: 120px; */
  min-height: 40px;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: var(--money-line-value-bg); */
  /* background: var(--live-container-background); */
  border: 1px solid var(--modal-border);
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--ion-color-primary);
}

.desktop-card-event-btn:hover {
  background: var(--orange);
  transition: .3s;
  cursor: pointer;
}

.desktop-card-event-btn:hover div,
.desktop-card-event-btn.chosen div {
  color: #ffffff;
}

.desktop-card-event-btn.chosen {
  transition: .3s;
  background: var(--orange);
}

.desktop-card-event-cfc-label {
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px
}

.card-event-cfc-val {
  margin-left: 5px;
  opacity: .7;
}

.card-event-cfc-val p {
  margin: 0;
}

.one-third {
  /* width: 33%; */
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); */
}

.one-fourth {
  /* width: 24.5%; */
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)); */
}

.desktop-card-event-cfc-team {
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 0.21;
}

.desktop-card-event-cfc-container-bottom {
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  opacity: .5;
}

.desktop-card-event-cfc-container-bottom-item {
  text-align: center;
}

.desktop-card-event-cfc-container .home-top-segment {
  max-width: 315px;
  width: 50%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 32px;
  /* margin: 0 auto; */
  background: var(--money-line-value-bg);
  border: 2px solid var(--second-background);
}

/* body:not(dark) .desktop-card-event-cfc-container .home-top-segment ion-segment-button {
  --indicator-color: #ffffff;
  --color-checked: #3F3F3F;
} */

.desktop-card-event-cfc-container .home-top-segment ion-label {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.24px;
}

.desktop-card-event-cfc-container-slider {
  margin-top: 20px;
}

.desktop-card-event-cfc-container-slider .sliders-container {
  padding: 15px;
}

.desktop-card-event-cfc-container-slider .sliders-container-header {
  margin: 0;
  justify-content: center;
  align-items: center;
  position: relative;
}

.desktop-card-event-cfc-container-slider .sliders-container-header div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
}

.desktop-card-event-cfc-container-slider .slider-score {
  font-size: 24px;
  letter-spacing: 0.015em;
}

.desktop-card-event-cfc-container-slider .slider-team-name {
  position: absolute;
  text-align: inherit;
  font-size: 10px;
  line-height: 18px;
}

.desktop-card-event-cfc-container-slider .slider-team-name:first-child {
  left: 0;
}

.desktop-card-event-cfc-container-slider .slider-team-name:last-child {
  right: 0;
}

.desktop-card-event-cfc-container-slider .slider-stake {
  cursor: pointer;
}

.range-vl {
  width: 1px;
  margin-left: 25px;
  margin-right: 25px;
  background: #cccccc;
}

.desktop-card-event-cfc-container-slider .slider-team-body ion-range {
  padding: 0 0 0 8px;
  --bar-height: 4px;
}

/* 4k */

.list-of-bets.ultra .desktop-live-container-header {
  padding-left: 65px;
  padding-right: 15px;
}

.list-of-bets.ultra .desktop-live-container-header .title {
  margin-right: 20px;
  padding-right: 20px;
  font-size: 30px;
  line-height: 30px;
}

.list-of-bets.ultra .desktop-live-container-header .time {
  font-size: 30px;
  line-height: 30px;
}

.list-of-bets.ultra .list-of-bets-body {
  max-height: calc(100% - 100px);
}

.list-of-bets.ultra .list-of-bets-filter-container {
  margin-top: 30px;
  padding: 0 20px 0 65px;
}

.list-of-bets.ultra .list-of-bets-filter-btn {
  min-width: 132px;
  height: 60px;
  margin-right: 30px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 30px;
  font-size: 30px;
  line-height: 30px;
}

.list-of-bets.ultra .btn-hl {
  height: 2px;
  bottom: 2px;
  left: 15px;
  right: 15px;
}

.list-of-bets.ultra .list-of-bets-filter-settings {
  width: 52px;
  min-width: 52px;
  height: 52px;
  margin-top: 4px;
  margin-left: 20px;
}

.list-of-bets.ultra .list-of-bets-filter-settings svg {
  width: 24px;
  height: 24px;
}

.list-of-bets.ultra .list-of-bets-header {
  margin-top: 65px;
  margin-bottom: 40px;
  padding-left: 65px;
  padding-right: 20px;
}

.list-of-bets.ultra .list-of-bets-header-left {
  font-size: 36px;
}

.list-of-bets.ultra .card-event-toggle-first-label {
  margin-right: 15px;
  font-size: 30px;
}

.list-of-bets.ultra .card-event-toggle-second-label {
  margin-left: 15px;
  font-size: 30px;
}

.list-of-bets.ultra .toggle-card {
  height: 43.97px;
  width: 81.94px;
  --handle-border-radius: 50%;
  --border-radius: 20px;
  --handle-width: 31.98px;
  --handle-height: 31.98px;
  --handle-spacing: 6px;
}

.list-of-bets.ultra .list-of-bets-header-right .more-icon {
  width: 42px;
  height: 42px;
  margin-left: 50px;
}

.list-of-bets.ultra .list-of-bets-header-right .update-icon, .list-of-bets.ultra .list-of-bets-header-right .star-icon {
  width: 36px;
  height: 36px;
  margin-left: 40px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container {
  padding: 20px 65px;
  margin-bottom: 10px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container.close {
  height: 72px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-header {
  margin-bottom: 20px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-header-label {
  font-size: 30px;
  line-height: 30px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container svg {
  width: 32px;
  height: 32px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container .arrow-down {
  width: 26px;
  height: 14px;
  margin-left: 40px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-btns {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-btns.one-fourth {
  grid-template-columns: repeat(4, 1fr);
}

.list-of-bets.ultra .desktop-card-event-cfc-container-btns .desktop-card-event-btn {
  height: 80px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 30px;
  line-height: 30px;
}

.list-of-bets.ultra .desktop-card-event-cfc-label {
  margin-right: 10px;
}

.list-of-bets.ultra .desktop-card-event-cfc-team {
  font-size: 30px;
}

.list-of-bets.ultra .card-event-cfc-val {
  margin-left: 10px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-bottom {
  margin-top: 15px;
  font-size: 30px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container .home-top-segment {
  max-width: 650px;
  max-height: 65px;
  height: 65px;
  border: none;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-slider .sliders-container {
  padding: 30px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-slider .slider-team-name {
  font-size: 30px;
  line-height: 30px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-slider .slider-score {
  font-size: 48px;
}

.list-of-bets.ultra .desktop-card-event-cfc-container-slider .slider-team-body ion-range {
  margin-top: 10px;
  padding: 0 0 0 16px;
  --bar-height: 8px;
}

.list-of-bets.ultra .slider-team-body ion-range {
  --knob-size: 32px;
}

.list-of-bets.ultra .slider-team-body ion-range::part(tick) {
  width: 4px;
  height: 16px;
  top: 13px;
}

.list-of-bets.ultra .slider-team-body ion-range::part(pin) {
  font-size: 20px;
}

.list-of-bets.ultra .range-vl {
  width: 2px;
  margin-left: 50px;
  margin-right: 50px;
}

.list-of-bets.ultra .slider-stake {
  margin-top: 10px;
  height: 80px;
  font-size: 30px;
}

.list-of-bets.ultra .close-betslip-icon {
  width: 30px;
  height: 32px;
  margin-right: 20px;
}
