/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  --orange: #D9773C;
  --red: #D04D4D;
  --acpink: #BC60AD;
  --violet: #8360BC ;
  --purple: #576EE8;
  --light-blue: #42A5BB;
  --acgreen: #63AB62;
  --orange-gradient: linear-gradient(90deg, #D04D4D, #D9773C);
  --red-gradient: linear-gradient(90deg, #D04D4D, #BC60AD);
  --pink-gradient: linear-gradient(90deg, #BC60AD, #8360BC);
  --violet-gradient: linear-gradient(90deg, #8360BC, #576EE8);
  --acpurple-gradient: linear-gradient(90deg, #576EE8, #42A5BB);
  --light-blue-gradient: linear-gradient(90deg, #42A5BB, #63AB62);
  --green-gradient: linear-gradient(90deg, #63AB62, #BDA241);
  --orange-gradient-end: #D9773C;
  --red-gradient-end: #BC60AD;
  --pink-gradient-end: #8360BC;
  --violet-gradient-end: #576EE8;
  --acpurple-gradient-end: #42A5BB;
  --light-blue-gradient-end: #63AB62;
  --green-gradient-end: #BDA241;
  --orange-light: #D2753C;
  --green: #6FCF97;
  --magenta: #c72670;
  --gold: #ab902e;
  --orange-gradient-left-color: #BE3838;
  --orange-gradient-right-color: #CE7619;
  --red-gradient-left-color: #E13030;
  --red-gradient-right-color: #F26CB4;
  --pink-gradient-left-color: #F26CB4;
  --pink-gradient-right-color: #AF6CF2;
  --violet-gradient-left-color: #AF6CF2;
  --violet-gradient-right-color: #6C79F2;
  --acpurple-gradient-left-color: #6C79F2;
  --acpurple-gradient-right-color: #55B9E4;
  --blue-gradient-left-color: #55B9E4;
  --blue-gradient-right-color: #5DE26A;
  --green-gradient-left-color: #5DE26A;
  --green-gradient-right-color: #CCDA30;
  --blue: #5663D7;
  --brown: #F4DEDE;
  --old-red: #FF5050;
  --old-light-blue: #26a2c7;
  --old-acgreen: #219653;
  --light-gray: #c2c2c2;
  --black-white: #000000;
  --opacity-text-color: rgba(63, 63, 63, .5);
  --opacity-dark-text-color: #ffffff;
  --logo-color: #000000;
  --first-background: #FFFFFF;
  --second-background: #F4F4F4;
  --third-background: #F4F4F4;
  --fourth-background: #E9EBED;
  --fifth-background: #ffffff;
  --sixth-background: #ffffff;
  --seventh-background: #F4E8E0;
  --eighth-background: #FBFBFB;
  --nineth-background: #FFFFFF;
  --login-background: #FFFFFF;
  --segment-border: #E9E9E9;
  --btn-background: #ffffff;
  --card-background: #F4F4F4;
  --news-card-background: #ffffff;
  --modal-background: #E9EBED;
  --sidebar-text-gray: #7A7A7A;
  --border-gray: #D2D2D2;
  --outgoing-mess--dt-bg: #FFFFFF;
  --incoming-mess-text-bg: #F4F4F4;
  --chat-go-top-btn: #ffffff;
  --chat-textarea-bg: #EBEDEF;
  --segment-bg-gray: #FFFFFF;
  --text-white: #3F3F3F;
  --money-line-value-bg: #F4F4F4;
  --btn-close-bg: #DBDBDB;
  --border-light-gray: #DFDFDF;
  --text-red: #D83F3F;
  --text-green: #6FCF97;
  --toggle-blue: #707EFF;
  --toggle-pink: #FF7070;
  --ion-background-color: #e9ebed;
  --ion-menu-color: #ffffff;
  --selected-bg: #f4f4f4;
  --dirty-white: #E9E9E9;
  --home-top-segment-button-color: rgba(63, 63, 63, 0.5);
  --live-container-background: #ffffff;
  --stake-btn-bg: #F4F4F4;
  --list-of-bets-btn-color: #000000;
  --slider-bar-color: #707EFF;
  --right-bar-header-active-bg: #ffffff;
  --betslip-money-line-value-bg: #ffffff;
  --arrow-icon-color: #979797;
  --arrow-right-icon-color: #0A0A0A;
  --tablet-card-event-bg: #fafafa;
  --tablet-card-event-btn: #E9EBED;
  --tablet-card-event-cfc-bg: #ffffff;
  --close-card-icon: #3F3F3F;
  --mobile-card-event-bg: #fafafa;
  --mobile-graph-button: #F4F4F4;
  --input-placeholder-color: rgba(63, 63, 63, 0.3);
  --icon-default: #BFBFBF;
  --filters-icon: #BFBFBF;
  --ul-background: #e9e9e9;
  /* Dark mode variables go here */
  --inner-header-background: #F4F4F4;
  --left-bar-background: #ffffff;
  --padding: 0px;
  --ion-color-primary: #3F3F3F;
  --shaded-color: rgba(255, 255, 255, 0.3);
  --ion-color-tabbar: #ffffff;
  --color-grey: #818181;
  --layer: transparent;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #3F3F3F;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-toolbar-background: #fff;
  --card-color: #45474a;
  --ion-color-banner: #fff;
  --ion-backdrop-color: #000000;
  --ion-backdrop-opacity: 0.8;
  --svg-color: rgba(255, 255, 255, 0.7);
  --ion-dark: #45484a;
  --ion-header: #000000;
  --border-split: #E9E9E9;
  --ion-toggle: #C8C8C8;
  --arrow-code-icon: url('../images/arrow-code-dark.svg');
  --input-background: #F7F8F9;
  --input-border: #DFDFDF;
  --account-image-bgr: #E1E1E1;
  --account-mobile-image-bgr: #DBDBDB;
  --account-image: #BFBFBF;
  --account-mobile-image: #A1A1A1;
  --close-icon-color: #C5C5C5;
  --area-border: #DFDFDF;
  --shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
  --shadow-chat-mess: 10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05);
  --header-icon-bgr: #E1E1E1;
  --header-icon-color: #A0A0A0;
  --header-wallet-icon: #BFBFBF;
  --menu-item-bgr: #F5F5F5;
  --loan-orange-btn: #F3833F;
  --loan-green-btn: #6FCF97;
  --arrow-fold-color: #3F3F3F;
  --select-bgr: #FFFFFF;
  --select-tablet-bgr: #E9EBED;
  --wallet-tabs-border: #DBDBDB;
  --rules-text-bgr: #F4F4F4;
  --settings-select-bgr: #FDFDFD;
  --leagues-card-bgr: #FFFFFF;
  --leagues-head-bgr: #FFFFFF;
  --notification-item-color: #333333;
  --grey-segment-button: #EAEAEA;
  --daypicker-bg: #E1E1E1;
  --daypicker-selected-bg: #ECE1DB;
  --purple-gradient: linear-gradient(284.69deg, #DA58C5 0%, #BF64DE 74.62%, #B768E7 100%);
  --settings-chat-bubble-bg: #FFFFFF;
  --modal-border: #DDDDDF;
  --minus-plus-bgr: #F4F4F4;
}

body.dark {
  --orange: #99562D;
  --red: #A23B3B;
  --acpink: #99396D;
  --violet: #7038A8;
  --purple: #3D49B1;
  --light-blue: #177097;
  --acgreen: #317D38;
  --orange-gradient: linear-gradient(90deg, #A13B3B, #99562D);
  --red-gradient: linear-gradient(90deg, #A13B3B, #99396D);
  --pink-gradient: linear-gradient(90deg, #99396D, #7038A8);
  --violet-gradient: linear-gradient(90deg, #7038A8, #3D49B1);
  --acpurple-gradient: linear-gradient(90deg, #3D49B1, #177097);
  --light-blue-gradient: linear-gradient(90deg, #177097, #317D38);
  --green-gradient: linear-gradient(90deg, #317D38, #928513);
  --orange-gradient-end: #99562D;
  --red-gradient-end: #99396D;
  --pink-gradient-end: #7038A8;
  --violet-gradient-end: #3D49B1;
  --acpurple-gradient-end: #177097;
  --light-blue-gradient-end: #317D38;
  --green-gradient-end: #928513;
  --orange-light: #7A411F;
  --green: #6FCF97;
  --magenta: #c72670;
  --gold: #ab902e;
  --orange-gradient-left-color: #BE3838;
  --orange-gradient-right-color: #CE7619;
  --blue: #5663D7;
  --brown: #492b2b;
  --old-red: #FF5050;
  --old-light-blue: #26a2c7;
  --old-acgreen: #219653;
  --light-gray: #c2c2c2;
  --black-white: #FFFFFF;
  --opacity-text-color: rgba(255, 255, 255, .5);
  --opacity-dark-text-color: rgba(255, 255, 255, .5);
  --logo-color: #E9E9E9;
  --first-background: #242424;
  --second-background: #333333;
  --third-background: #424242;
  --fourth-background: #383838;
  --fifth-background: #232323;
  --sixth-background: #444444;
  --seventh-background: #513D31;
  --eighth-background: #333333;
  --nineth-background: #333333;
  --login-background: #000000;
  --segment-border: #525252;
  --btn-background: #434242;
  --card-background: #343333;
  --news-card-background: #343333;
  --modal-background: #1e1e20;
  --sidebar-text-gray: #7A7A7A;
  --border-gray: #363636;
  --outgoing-mess--dt-bg: #3D3D3D;
  --incoming-mess-text-bg: #1A1A1A;
  --chat-go-top-btn: #565656;
  --chat-textarea-bg: #565656;
  --segment-bg-gray: #636366;
  --text-white: #ffffff;
  --money-line-value-bg: #262626;
  --btn-close-bg: #585858;
  --border-light-gray: #5A5A5A;
  --text-red: #D83F3F;
  --text-green: #6FCF97;
  --toggle-blue: #707EFF;
  --toggle-pink: #FF7070;
  --selected-bg: #3E3D3C;
  --dirty-white: #E9E9E9;
  --home-top-segment-button-color: rgba(255, 255, 255, 0.5);
  --live-container-background: #333333;
  --stake-btn-bg: #242424;
  --list-of-bets-btn-color: #F2F2F2;
  --slider-bar-color: rgba(112, 126, 255, 0.3);
  --right-bar-header-active-bg: #3D3D3D;
  --betslip-money-line-value-bg: #262626;
  --arrow-icon-color: #ffffff;
  --arrow-right-icon-color: #ffffff;
  --tablet-card-event-bg: #333333;
  --tablet-card-event-btn: #242424;
  --tablet-card-event-cfc-bg: #383838;
  --close-card-icon: #ffffff;
  --mobile-card-event-bg: #343333;
  --mobile-graph-button: #262626;
  --input-placeholder-color: rgba(255, 255, 255, 0.3);
  --icon-default: #585858;
  --filters-icon: #737373;
  --ul-background: #454545;
  /* Dark mode variables go here */
  --inner-header-background: #333333;
  --left-bar-background: #242424;
  --padding: 0px;
  --ion-color-primary: #ffffff;
  --shaded-color: rgba(255, 255, 255, 0.3);
  --ion-color-tabbar: #ffffff;
  --color-grey: #818181;
  --ion-background-color: #000000;
  --ion-menu-color: #242424;
  --layer: transparent;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-toolbar-background: #fff;
  --card-color: #45474a;
  --ion-color-banner: #fff;
  --ion-backdrop-color: #000000;
  --ion-backdrop-opacity: 0.8;
  --svg-color: rgba(255, 255, 255, 0.7);
  --ion-dark: #45484a;
  --ion-header: #000000;
  --border-split: #3E3E3E;
  --ion-toggle: #737373;
  --arrow-code-icon: url('../images/arrow-code-light.svg');
  --input-background: #242424;
  --input-border: #525252;
  --account-image-bgr: #424242;
  --account-mobile-image-bgr: #737373;
  --account-image: #787878;
  --account-mobile-image: #515151;
  --close-icon-color: #737373;
  --area-border: #424242;
  --shadow: none;
  --shadow-chat-mess: none;
  --header-icon-bgr: #737373;
  --header-icon-color: #FFFFFF;
  --header-wallet-icon: #A6A6A6;
  --menu-item-bgr: #2a2a2a;
  --loan-orange-btn: #C56226;
  --loan-green-btn: #219653;
  --arrow-fold-color: #CBCBCB;
  --select-bgr: #424242;
  --select-tablet-bgr: #424242;
  --wallet-tabs-border: #242424;
  --rules-text-bgr: #242424;
  --settings-select-bgr: #333333;
  --leagues-card-bgr: #333333;
  --leagues-head-bgr: #424242;
  --notification-item-color: #CBCBCB;
  --grey-segment-button: #6C6C6C;
  --daypicker-bg: #000000;
  --daypicker-selected-bg: #2E180B;
  --purple-gradient: linear-gradient(284.69deg, rgba(146, 43, 129, .5) 0%, rgba(141, 63, 189, .5) 100%);
  --settings-chat-bubble-bg: #3F3F3F;
  --modal-border: #525252;
  --minus-plus-bgr: rgba(255, 255, 255, 0.1);
}

*::before {
  display: none;
}

ion-segment-button::part(indicator) {
  padding-left: 0px;
  padding-right: 0px;
}

ion-segment-button {
  margin-bottom: 0px;
}

.modal-wrapper.sc-ion-modal-ios, .modal-shadow.sc-ion-modal-ios {
  background: none;
}