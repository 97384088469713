.chat-toggle {
  margin-top: 3px;
  background: var(--card-background);
  border-radius: 5px 5px 0px 0px;
  flex: 0 0 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-toggle ion-segment {
  margin: 0 auto;
  background: var(--fifth-background);
}

.chat-container {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.input-area {
  position: relative;
  width: 100%;
  height: 50px;
  flex: 0 0 50px;
}

.chat-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: var(--sixth-background);
  font-size: 15px;
  letter-spacing: -0.408px;
  border-radius: 25px;
  padding-left: 27px;
  padding-right: 55px;
}

.input-area form {
  width: 100%;
  height: 100%;
}

.chat-input::-moz-placeholder {
  color: var(--input-placeholder-color);
  letter-spacing: -0.408px;
  font-size: 15px;
}

.chat-input:-ms-input-placeholder {
  color: var(--input-placeholder-color);
  letter-spacing: -0.408px;
  font-size: 15px;
}

.chat-input::-webkit-input-placeholder {
  color: var(--input-placeholder-color);
  letter-spacing: -0.408px;
  font-size: 15px;
}

.chat-input::-ms-input-placeholder {
  color: var(--input-placeholder-color);
  letter-spacing: -0.408px;
  font-size: 15px;
}

.chat-input::placeholder {
  color: var(--input-placeholder-color);
  letter-spacing: -0.408px;
  font-size: 15px;
}

.send-button {
  position: absolute;
  border: none;
  outline: none;
  width: 51px;
  height: 51px;
  right: 0;
  z-index: 5;
  border-radius: 50%;
  background: var(--orange);
  /* background-image: url(../images/arrow-up.svg); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  background-size: 20px;
}

.chat-window {
  width: 95%;
  padding: 0 5px;
  overflow: auto;
  margin: 0 auto;
  flex: 1 0 50vh;
}

.incoming-message, .outgoing-message {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  /* height: auto; */
  min-height: 43px;
  /* background: var(--seventh-background); */
  border-radius: 15px;
  width: 80%;
  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;
  box-shadow: var(--shadow);
}

.outgoing-message {
  background: var(--orange);
}

.outgoing-message svg {
  fill: var(--orange);
}

.incoming-message .tail, .outgoing-message .tail {
  width: 20px;
  height: 14px;
  right: -4px;
  bottom: 0;
  position: absolute;
}

.incoming-message {
  margin-left: 0px;
  margin-right: auto;
  background: var(--nineth-background);
}

/* .outgoing-message .tail {
  fill: var(--seventh-background);
} */

.incoming-message .tail {
  left: -5px;
  fill: var(--nineth-background);
}

.message-time {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.408px;
  opacity: 0.5;
  text-align: right;
  /* color: #ffffff; */
  /* margin-right: 10px; */
  /* margin-top: -5px; */
  /* flex: 0 0 15px; */
  /* margin-bottom: 3px; */
  /* color: var(--text-white); */
}

.message-text {
  margin: 0;
  padding: 0;
  letter-spacing: -0.408px;
  /* color: #ffffff; */
  /* margin-top: 5px; */
  /* font-size: 15px; */
  /* line-height: 22px; */
  /* color: var(--text-white); */
  /* padding-left: 14px; */
  /* padding-right: 20px; */
  /* padding-top: 7px; */
}

.incoming-message .message-text, .incoming-message .message-time {
  color: var(--text-white);
}

.outgoing-message .message-text, .outgoing-message .message-time {
  color: #ffffff;
}

.chat-content {
  --keyboard-offset: 0px !important;
}

/* Desktop */

.chat-container-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  /* padding-left: 0; */
}

.chat-container.desktop-live-main-container {
  min-width: inherit;
}

.desktop-chat-header {
  height: 50px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--card-background);
}

.desktop-chat-header-left {
  align-items: center;
}

.desktop-chat-userpic {
  width: 21px;
  height: 21px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--header-icon-bgr);
}

.desktop-chat-userpic svg {
  fill: var(--header-wallet-icon);
}

.desktop-chat-header-label {
  font-size: 16px;
  line-height: 114.2%;
  color: #ffffff;
  padding: 7px 10px 3px;
  border-radius: 5px;
}

.desktop-chat-header-right {
  align-items: center;
}

.desktop-chat-notice-container {
  width: 24px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desktop-chat-help {
  cursor: pointer;
  fill: #A6A6A6;
}

.desktop-chat-help.white {
  fill: #ffffff;
}

.desktop-chat-help.black {
  fill: #585858;
}

.help-popover {
  --min-width: 330px;
}

.help-popover-container {
  padding: 16px;
  font-size: 14px;
  line-height: 21px;
}

.help-popover-container p {
  margin: 0;
}

.help-popover-container p.mt-20 {
  margin-top: 20px;
}

.help-popover-container p span {
  font-weight: 800;
}

.desktop-chat-window {
  width: 100%;
  padding: 30px 20px;
  height: calc(100% - 115px);
  overflow: auto;
}

.desktop-outgoing-message-container {
  max-width: 500px;
  margin-left: auto;
  margin-bottom: 10px;
}

.text-container.found {
  background: var(--icon-default) !important;
}

.desktop-outgoing-message-top {
  margin-bottom: 10px;
  align-items: center;
}

.desktop-outgoing-details {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.desktop-text-container {
  /*height: auto;*/
  min-height: 35px;
  width: 420px;
  margin-right: 15px;
  /*padding: 10px 15px 10px 20px;*/
  /*background: var(--outgoing-mess--dt-bg);*/
  background: var(--orange);
  border: 1px solid rgba(143, 143, 143, 0.25);
  border-radius: 10px;
  border-bottom-right-radius: 0 !important;
  color: #ffffff;
  position: relative;
  /*font-size: 16px;*/
  /*line-height: 26px;*/
  box-shadow: var(--shadow-chat-mess);
}

.desktop-outgoing-status {
  align-self: flex-end;
}

.desktop-message-time {
  font-weight: 600;
  font-size: 14px;
  color: rgba(140, 140, 140, 0.7);
}

.desktop-outgoing-message-container .desktop-message-time {
  padding-right: 40px;
  text-align: right;
}

.desktop-incoming-message-container .desktop-message-time {
  padding-left: 45px;
  text-align: left;
}

.desktop-incoming-message-container {
  max-width: 560px;
  margin-bottom: 10px;
}

.desktop-incoming-message-top {
  margin-bottom: 10px;
  align-items: center;
}

.desktop-incoming-avatar {
  min-width: 27px;
  width: 27px;
  min-height: 27px;
  height: 27px;
  margin-right: 15px;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--header-icon-bgr);
}

.desktop-incoming-avatar svg {
  width: 14px;
  height: 15px;
  fill: var(--header-wallet-icon);
}

.desktop-incoming-text-container {
  /* height: auto; */
  min-height: 35px;
  width: 520px;
  margin-right: 15px;
  /* padding: 20px 30px; */
  background: var(--incoming-mess-text-bg);
  border-radius: 0 10px 10px 10px;
  /* font-weight: bold; */
  /* font-size: 17px; */
  /* line-height: 18px; */
}

.desktop-incoming-details {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.go-top-btn {
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 35px;
  bottom: 90px;
  border-radius: 100%;
  background: var(--chat-go-top-btn);
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.go-top-btn .arrow-down {
  transform: rotate(180deg);
}

.desktop-input-area-container {
  width: 97%;
  height: 45px;
  /* padding: 0 15px; */
  margin: 0 auto;
  border-radius: 8px;
  background: var(--chat-textarea-bg);
}

.desktop-input-area-container form {
  width: 100%;
}

.chat-add-container {
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  /* background: var(--chat-textarea-bg); */
  border-right: 1px solid var(--first-background);
}

.chat-add-container img {
  cursor: pointer;
}

.desktop-chat-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 8px;
  background: var(--chat-textarea-bg);
  font-size: 15px;
  letter-spacing: -0.408px;
  padding-left: 15px;
  padding-right: 15px;
}

.home-top-segment.chat-tablet {
  width: 80%;
  height: 30px;
  margin-bottom: 10px;
  background: var(--nineth-background);
}

.tablet-chat-container {
  /* height: calc(100vh - 130px); */
  height: calc(100vh - 90px);
  flex-direction: column;
  justify-content: space-between;
  background: var(--first-background);
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.tablet-chat-header {
  height: 55px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--card-background);
  border-radius: 5px 5px 0px 0px;
}

.tablet-chat-header-left, .tablet-chat-header-right {
  align-items: center;
}

.tablet-chat-header-label {
  padding-top: 3px;
  font-size: 15px;
  font-weight: normal;
  color: #ffffff;
  padding: 7px 10px 3px;
  border-radius: 5px;
}

.tablet-chat-header .calendar-container {
  margin-left: 35px;
  justify-content: flex-start;
}

.tablet-chat-header .calendar-container .calendar-label {
  margin-left: 8px;
  margin-right: auto;
}

.tablet-chat-notice {
  margin-right: 15px;
  cursor: pointer;
}

.tablet-chat-window {
  width: 100%;
  /* height: calc(100% - 95px); */
  padding: 30px 20px;
  overflow: auto;
}

.tablet-input-area-container {
  width: 100%;
  height: 45px;
  margin: 0 auto;
  border-radius: 8px;
  background: var(--chat-textarea-bg);
}

.tablet-input-area-container form {
  width: 100%;
  display: flex;
}

.tablet-chat-container .go-top-btn {
  right: 50px;
  bottom: 80px;
}

.tablet-chat-input {
  width: 100%;
  /* height: 100%; */
  outline: none;
  border: none;
  border-radius: 8px;
  background: var(--chat-textarea-bg);
  font-size: 15px;
  letter-spacing: -0.408px;
  padding-left: 15px;
  padding-right: 15px;
}

.send-button.tablet {
  position: inherit;
  min-width: 45px;
  width: 45px;
  height: 45px;
  /* background: var(--purple); */
  border-radius: 5px;
  /* background-image: url(../images/send.svg); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: 20px; */
}

.desktop-chat-header {
  height: 50px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--card-background);
}

.chat-textsize-panel {
  margin-right: 100px;
  margin-left: 100px;
  display: flex;
  align-items: center;
}

.chat-textsize-title {
  margin-right: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.chat-textsize-title svg {
  width: 24px;
  height: 24px;
  margin-right: 7px;
  fill: #A6A6A6;
}

/* 4k */

.chat-container-wrapper.ultra .desktop-chat-header {
  height: 100px;
  padding: 0 50px;
}

.chat-container-wrapper.ultra .desktop-chat-userpic {
  width: 55px;
  height: 55px;
  margin-right: 40px;
}

.chat-container-wrapper.ultra .desktop-chat-userpic svg {
  width: 29px;
  height: 31px;
}

.chat-container-wrapper.ultra .desktop-chat-header-label {
  font-size: 30px;
  line-height: 46px;
}

.chat-container-wrapper.ultra .desktop-chat-notice-container {
  width: 48px;
  height: 48px;
  margin-right: 40px;
}

.chat-container-wrapper.ultra .desktop-chat-notice {
  width: 36px;
  height: 36px;
}

.chat-container-wrapper.ultra .desktop-chat-notice.dis {
  width: 100%;
  height: auto;
}

.chat-container-wrapper.ultra .searchbar {
  width: 540px;
  height: 65px;
  margin-right: 40px;
  --border-radius: 15px;
}

.chat-container-wrapper.ultra .searchbar .searchbar-input {
  padding-left: 30px !important;
  font-size: 30px;
  line-height: 45px;
}

.chat-container-wrapper.ultra .searchbar .searchbar-search-icon {
  width: 28px;
  left: inherit;
  right: 30px;
}

.chat-container-wrapper.ultra .desktop-chat-help {
  width: 40px;
  height: 40px;
}

.chat-container-wrapper.ultra .desktop-chat-window {
  padding: 60px 40px;
  height: calc(100% - 210px);
}

.chat-container-wrapper.ultra .desktop-outgoing-message-container {
  max-width: 1000px;
}

.chat-container-wrapper.ultra .desktop-outgoing-message-top {
  margin-bottom: 30px;
}

.chat-container-wrapper.ultra .desktop-outgoing-details {
  width: 48px;
  height: 48px;
  margin-right: 40px;
}

.chat-container-wrapper.ultra .desktop-text-container {
  min-height: 86px;
  width: 840px;
  margin-right: 25px;
  padding: 25px 30px 25px 40px;
  /* border-radius: 10px 10px 0 10px; */
  font-size: 30px;
  line-height: 50px;
}

.chat-container-wrapper.ultra .desktop-outgoing-status {
  width: 32px;
  height: 32px;
}

.chat-container-wrapper.ultra .desktop-outgoing-message-container .desktop-message-time {
  padding-right: 70px;
}

.chat-container-wrapper.ultra .desktop-message-time {
  font-size: 30px;
}

.chat-container-wrapper.ultra .desktop-incoming-message-container {
  max-width: 1120px;
}

.chat-container-wrapper.ultra .desktop-incoming-message-top {
  margin-bottom: 30px;
}

.chat-container-wrapper.ultra .desktop-incoming-avatar {
  min-width: 70px;
  width: 70px;
  min-height: 70px;
  height: 70px;
  margin-right: 30px;
}

.chat-container-wrapper.ultra .desktop-incoming-avatar svg {
  width: 37px;
  height: 40px;
}

.chat-container-wrapper.ultra .desktop-incoming-text-container {
  min-height: 86px;
  width: 1040px;
  margin-right: 40px;
  padding: 35px 60px;
  font-size: 30px;
  line-height: 50px;
}

.chat-container-wrapper.ultra .desktop-incoming-details {
  width: 48px;
  height: 48px;
}

.chat-container-wrapper.ultra .desktop-incoming-message-container .desktop-message-time {
  padding-left: 90px;
}

.chat-container-wrapper.ultra .go-top-btn {
  width: 84px;
  height: 84px;
  right: 70px;
  bottom: 170px;
}

.chat-container-wrapper.ultra .go-top-btn .arrow-down {
  width: 22px;
  height: 13px;
}

.chat-container-wrapper.ultra .desktop-input-area-container {
  width: 97%;
  height: 90px;
  border-radius: 16px;
}

.chat-container-wrapper.ultra .chat-add-container {
  width: 90px;
  height: 90px;
  border-right-width: 3px;
}

.chat-container-wrapper.ultra .chat-add-container img {
  width: 43px;
  height: 43px;
}

.chat-container-wrapper.ultra .desktop-chat-input {
  border-radius: 16px;
  font-size: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.chat-container-wrapper.ultra .incoming-message .tail {
  width: 40px;
  height: 28px;
  right: -8;
  bottom: 0;
}

/* Text size */

.chat-container-wrapper.ultra .chat-textsize-title {
  margin-right: 50px;
  font-size: 30px;
}

.chat-container-wrapper.ultra .chat-textsize-title svg {
  width: 48px;
  height: 48px;
  margin-right: 14px;
}

@media screen and (max-width: 1280px) {
  .chat-textsize-panel {
    margin-right: 30px;
    margin-left: 30px;
  }
  .chat-textsize-panel .interface-textsize-list ion-item {
    max-width: 80px;
  }
  .chat-textsize-panel .interface-textsize-list ion-label.sc-ion-label-ios-h {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .chat-textsize-panel {
    margin-right: 0;
    margin-left: 15px;
  }
  .chat-textsize-title {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .chat-container {
    /* height: 99%; */
    height: calc(100% - 64px);
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 3400px) {
  .chat-container {
    /* height: 99%; */
    height: calc(99% - 64px);
  }
  .outgoing-message .tail {
    width: 40px;
    height: 28px;
    right: -8px;
    bottom: 0;
  }
}